export interface Photo {
  id: number;
  title: string;
  orientation: 'h' | 'v';
  role: string;
  src: string;
  year: string;
}

export function getPhotos(id?: number): Photo[] {
  let counter = 0;
  const photos: Photo[] = [
    {
      id: counter++,
      title: 'Sweetest of Hearts',
      orientation: 'v',
      role: 'himself',
      src: '/img/green-wall.jpg',
      year: '1987 - present',
    },
    {
      id: counter++,
      title: "Impeccable Wayne's World Quoter",
      orientation: 'v',
      role: 'The Almanac',
      src: '/img/waynes-world.jpeg',
      year: '1992 - present',
    },
    {
      id: counter++,
      title: "Positive Force Meeting Survivor",
      orientation: 'h',
      role: 'El Masochista',
      src: '/img/ms.jpg',
      year: 'late 2000s-mid 2010s',
    },
    {
      id: counter++,
      title: "Ghoul",
      orientation: 'v',
      role: "Lori Porcelli's pretend child to dump off food that she didn't want in her cabinets",
      src: '/img/ghoul.jpeg',
      year: 'late 2000s-mid 2010s',
    },
    {
      id: counter++,
      title: "Salad Days Of Summer",
      orientation: 'h',
      role: "Ice-cream Eating Motherfucker",
      src: '/img/ice_cream.jpg',
      year: '2012',
    },
    {
      id: counter++,
      title: "Weekend at Bernie's 7",
      orientation: 'v',
      role: "Weekend",
      src: '/img/bernies.jpg',
      year: '2012',
    },
    {
      id: counter++,
      title: 'Cat Whisperer',
      orientation: 'v',
      role: 'Cat Whisperer',
      src: '/img/meow.jpeg',
      year: '2012',
    },
    {
      id: counter++,
      title: 'Midnight Driver',
      orientation: 'v',
      role: 'Uncredited',
      src: '/img/midnight_driver.jpg',
      year: '2012',
    },
    {
      id: counter++,
      title: 'PCRM Nutrition Department',
      orientation: 'h',
      role: 'Numbers McGee',
      src: '/img/nutrition.jpg',
      year: '2012-present',
    },
    {
      id: counter++,
      title: 'Uncle Rosendo',
      orientation: 'h',
      role: 'Uncle Rosendo (recurring character)',
      src: '/img/tío.png',
      year: '2012, 2015',
    },
    {
      id: counter++,
      title: 'Long distance birthday friend',
      orientation: 'h',
      role: 'Definitely not Timm',
      src: '/img/darth.jpeg',
      year: '2013',
    },
    {
      id: counter++,
      title: 'Cat Whisperer II',
      orientation: 'v',
      role: 'Cat Whisperer',
      src: '/img/machi.jpg',
      year: '2015',
    },
    {
      id: counter++,
      title: 'Pirates of the Carribean',
      orientation: 'v',
      role: 'Office Worker 2',
      src: '/img/pirata.jpg',
      year: '2015',
    },
    {
      id: counter++,
      title: 'Fast and the Furious 200',
      orientation: 'v',
      role: 'The Furious',
      src: '/img/flex.jpg',
      year: '2015',
    },
    {
      id: counter++,
      title: 'Frannywood',
      orientation: 'v',
      role: "Todd 'The Rod' The Rod",
      src: '/img/frannywood.png',
      year: '2015',
    },
    {
      id: counter++,
      title: 'Movie Night (series)',
      orientation: 'v',
      role: 'Original cast member',
      src: '/img/movie-night.jpeg',
      year: '2015-2020',
    },
    {
      id: counter++,
      title: 'Butt Pirates',
      orientation: 'v',
      role: 'Butt Pirate 1',
      src: '/img/butt-pirates.jpg',
      year: '2016',
    },
    {
      id: counter++,
      title: 'Fake Horse',
      orientation: 'h',
      role: 'One-Trick Pony',
      src: '/img/fake-horse.jpg',
      year: '2016',
    },
    {
      id: counter++,
      title: 'Pay It Forward',
      orientation: 'v',
      role: 'Drummer',
      src: '/img/pay-it-forward.jpg',
      year: '2016',
    },
    {
      id: counter++,
      title: 'Taco',
      orientation: 'v',
      role: 'El Asesino',
      src: '/img/taco.jpg',
      year: '2017',
    },
    {
      id: counter++,
      title: 'Superiority Burger ',
      orientation: 'h',
      role: 'The Shaggs Fan 1',
      src: '/img/sup-burger.jpg',
      year: '2017',
    },
    {
      id: counter++,
      title: 'I Could Kill You With Just Six Strings',
      orientation: 'v',
      role: 'Strummer',
      src: '/img/guitar.jpg',
      year: '2017',
    },
    {
      id: counter++,
      title: 'Punk Rock Karaoke',
      orientation: 'h',
      role: 'Backup-up vocalist, crooner',
      src: '/img/duet.jpg',
      year: '2017',
    },
    {
      id: counter++,
      title: 'Exceedingly Level-Headed Scientist',
      orientation: 'v',
      role: 'Dr. Rosendo',
      src: '/img/doctor.jpg',
      year: '2018',
    },
    {
      id: counter++,
      title: 'Kitten Whisperer (Prequel to Cat Whisperer)',
      orientation: 'h',
      role: 'Cat Whisperer',
      src: '/img/kitten-whisperer.jpg',
      year: '2018',
    },
    {
      id: counter++,
      title: 'House Guest',
      orientation: 'v',
      role: 'Man checking phone',
      src: '/img/house-guest.jpg',
      year: '2019',
    },
    {
      id: counter++,
      title: 'Love at First Sight: Rosendo and his Jaguar (What a Jag!)',
      orientation: 'v',
      role: 'Rosendo',
      src: '/img/jag.jpeg',
      year: '2019',
    },
    {
      id: counter++,
      title: 'Creepy Rosendo',
      orientation: 'v',
      role: 'Creepy Rosendo',
      src: '/img/creepy-rosendo.jpg',
      year: '2019',
    },
  ];

  return id === undefined ? photos : photos.filter(photo => id === photo.id);
}

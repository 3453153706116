import React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { getPhotos } from '../services/photoService';
import './MediaViewer.css';

interface RouteParams {
  id: string;
}

export const MediaViewer: React.FunctionComponent = () => {
  const id = parseInt(useParams<RouteParams>().id, 10);
  const minPhotoId = getPhotos().shift()?.id || 0;
  const maxPhotoId = getPhotos().pop()?.id || 0;
  const photo = getPhotos(id)[0];
  const caption = `As "${photo.role}" in <em>${photo.title}</em> (${photo.year})`;
  return (
    <article id="mediaviewer">
      <header>
        <div id="close">
          <Link to="/name">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" role="presentation">
              <path fill="none" d="M0 0h24v24H0V0z"></path>
              <path d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59 7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12 5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path>
            </svg>
            Close
          </Link>
        </div>
        {(id > minPhotoId) && <div><Link to={`/mediaviewer/${photo.id - 1}`}>Previous</Link></div>}
        {(id < maxPhotoId) && <div><Link to={`/mediaviewer/${photo.id + 1}`}>Next</Link></div>}
      </header>
      <img src={photo.src} alt={caption} width="100%" height="100%" />
      <hr />
      <section>
        <p className="star">Rosendo Flores</p>
        <p dangerouslySetInnerHTML={{__html: caption}}></p>
      </section>
      <hr />
    </article>
  );
}

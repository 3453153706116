import React from 'react';

export const Awards: React.FunctionComponent = () => {
  return (
    <article>
      <header>
        <h2>Awards</h2>
      </header>
      <section>
        <ul>
          <li>Best Laugh during any off-screen joke in any movie</li>
          <li>Best reenactment of fight sequences from late 80s/early 90s movies</li>
          <li>Official MarkZine Club VIP member</li>
          <li>Best on-screen friendship in <em>Paco</em></li>
          <li>DC’s Best Karaoke Singer (2013)</li>
          <li>Golden Cloves Awards: Garlic Chopping Contest (2014)</li>
          <li>Son Dance Awards: Leading Man at the Black Cat Dance Parties (2015)</li>
          <li>Best Decorated Guitar</li>
        </ul>
      </section>
    </article>
  );
}

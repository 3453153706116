import React from 'react';
import { getPhotos } from '../services/photoService';
import { getVideos } from '../services/videoService';
import { ThumbnailStrip } from './ThumbnailStrip';

export const Filmography: React.FunctionComponent = () => {
  return (
    <article>
      <header>
        <h2>Filmography</h2>
      </header>
      <section>
        <h3>Known For</h3>
        <ThumbnailStrip mediaService={getPhotos} />
      </section>
      <hr />
      <section>
        <h3>Videos</h3>
        <ThumbnailStrip mediaService={getVideos} />
      </section>
    </article>
  );
}

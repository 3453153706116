import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import './App.css';
import { Header } from './components/Header';
import { MediaViewer } from './components/MediaViewer';
import { Person } from './components/Person';
import { VideoPlayer } from './components/VideoPlayer';

export const App: React.FunctionComponent = () => {
  return (
    <div id="page">
      <Header />
      <Switch>
        <Route path="/mediaviewer/:id" component={MediaViewer} />
        <Route path="/videoplayer/:id" component={VideoPlayer} />
        <Route path="/name" component={Person} />
        <Route path="/*">
          <Redirect to="/name" />
        </Route>
      </Switch>
    </div> 
  );
}

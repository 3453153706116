import React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { getVideos } from '../services/videoService';
import './VideoPlayer.css';

interface RouteParams {
  id: string;
}

export const VideoPlayer: React.FunctionComponent = () => {
  const id = parseInt(useParams<RouteParams>().id, 10);
  const minVidId = getVideos().shift()?.id || 0;
  const maxVidId = getVideos().pop()?.id || 0;
  const vid = getVideos(id)[0];
  const caption = `${vid.title} (${vid.year})`;
  return (
    <article id="videoplayer">
      <header>
        <div id="close">
          <Link to="/name">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" role="presentation">
              <path fill="none" d="M0 0h24v24H0V0z"></path>
              <path d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59 7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12 5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path>
            </svg>
            Close
          </Link>
        </div>
        {(id > minVidId) && <div><Link to={`/videoplayer/${vid.id - 1}`}>Previous</Link></div>}
        {(id < maxVidId) && <div><Link to={`/videoplayer/${vid.id + 1}`}>Next</Link></div>}
      </header>
      <video width="100%" height="auto" src={vid.video} controls>
        This wouldn't be a good spoof of the IMDb site if it didn't have terrbile browser support!
      </video> 
      <hr />
      <section>
        <p className="star">Rosendo Flores</p>
        <p dangerouslySetInnerHTML={{__html: caption}}></p>
      </section>
      <hr />
    </article>
  );
}

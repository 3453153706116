import React, { Fragment } from 'react';
import { Awards } from './Awards';
import { Bio } from './Bio';
import { DidYouKnow } from './DidYouKnow';
import { Filmography } from './Filmography';

export const Person: React.FunctionComponent = () => {
  return (
    <Fragment>
      <Bio />
      <Filmography />
      <Awards />
      <DidYouKnow />
    </Fragment>
  );
}

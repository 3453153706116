import React from 'react';
import './Video.css';

interface VideoProps {
  url: string;
}

export const Video: React.FunctionComponent<VideoProps> = (props) => {
  const { url } = props;
  return (
    <section className="video">
      <iframe title="38 Years Later" datatype="text/html" width="720" height="405" src={url} frameBorder="0" allowFullScreen></iframe>
    </section>
  );
}
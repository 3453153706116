import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { getPhotos, Photo } from '../services/photoService';
import { getVideos, Video } from '../services/videoService';
import './ThumbnailStrip.css';

interface ThumbnailProps {
  id: number;
  mediaService: typeof getPhotos | typeof getVideos;
}

function isPhoto(media: Photo | Video): media is Photo {
  return (media as Photo).role !== undefined;
}

const Thumbnail: React.FunctionComponent<ThumbnailProps> = (props) => {
  const { id, mediaService } = props;
  const item = mediaService(id)[0];
  const altText = isPhoto(item) ? `In ${item.title} as ${item.role} (${item.year})` : `${item.title} (${item.year})`;
  const path = isPhoto(item) ? '/mediaviewer' : '/videoplayer';
  return (
    <Link to={`${path}/${item.id}`}>
      <div className="thumbnail-wrapper">
        <img src={`${item.src}`} alt={altText} className={`orientation-${item.orientation}`} />
      </div>
      {
        isPhoto(item) &&
        <Fragment>
          <small className="ellipse">{item.title}</small>
          <small className="ellipse text-muted">({item.year})</small>
        </Fragment>
      }
    </Link>
  );
};

interface ThumbailStripProps {
  mediaService: typeof getPhotos | typeof getVideos;
}

export const ThumbnailStrip: React.FunctionComponent<ThumbailStripProps> = (props) => {
  const { mediaService } = props;
  const media = mediaService();
  return (
    <ul className="thumbnail-strip">
      {media.map((item: Photo | Video) => (
        <li>
          <Thumbnail id={item.id} mediaService={mediaService} />
        </li>
      ))}
    </ul>
  );
}

import React from 'react';
import './Bio.css';
import { Video } from './Video';

export const Bio: React.FunctionComponent = () => {
  return (
    <article id="bio">
      <header>
        <h1>Rosendo Flores</h1>
        <p>
          Friend
          <span className="text-muted"> | </span>
          Bandmate
          <span className="text-muted"> | </span>
          Film Connoisseur
        </p>
      </header>
      <Video url="https://www.youtube.com/embed/9sd_YnrcdVU?modestbranding=1" />
      <section id="summary">
        <img src="img/coffee.jpg" alt="Ah, smooth!" title="Ah, smooth!" width="89" height="132" />
        <p>
          Rosendo Andrés Flores, born March 24, 1987, in San Antonio, Texas, is a well-known friend/bandmate/movie buddy/quoter of 90's
          cinema/researcher of IMDB birthdays/cat whisperer/appreciator of vegan foods/all around sweetheart.
        </p>
        <p>
          <strong>Born: </strong> March 24, 1987
        </p>
      </section>
      <hr />
      <section>
        <h3>Nicknames</h3>
        <ul>
          <li>RAF</li>
          <li>(a little too RAF)</li>
          <li>Little Brother</li>
          <li>Texas Rose</li>
          <li>Rowee</li>
          <li>Lori's Son</li>
          <li>Mijo</li>
          <li>The Florist</li>
          <li>Ross</li>
          <li>Rozz</li>
        </ul>
      </section>
    </article>
  );
}

import React from 'react';

export const DidYouKnow: React.FunctionComponent = () => {
  return (
    <article>
      <header>
        <h2>Did You Know</h2>
      </header>
      <section>
        <h3>Trivia</h3>
        <ul>
          <li>Rosendo is the best person to work next to.</li>
          <li>Rosendo has done yoga with Ray Cappo from Youth of Today.</li>
          <li>He can quote nearly every line from the Star Wars movies.</li>
          <li>Johnny Marr of The Smiths has met only one person named Rosendo in his entire life: Rosendo.</li>
          <li>In 2015, he played drums for no fewer than six bands while singing and playing guitar in Mirror Motives.</li>
        </ul>
      </section>
      <hr />
      <section>
        <h3>Quotes</h3>
        <ul>
          <li>Ahhhh, smooth</li>
          <li>I need more time!</li>
          <li>I can't believe he's gone.</li>
          <li>T.U.F.F.</li>
          <li>You gots't it.</li>
          <li>Small hand.</li>
          <li>Oh, guess who's birthday it is today?</li>
          <li>Guess who wrote that? Nile Rogers.</li>
        </ul>
      </section>
      <hr />
      <section>
        <h3>Trademark</h3>
        <p>Bleeding from the hands.</p>
      </section>
      <hr />
      <section>
        <h3>Star Sign</h3>
        <p>Aries</p>
      </section>

    </article>
  );
}

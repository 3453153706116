export interface Video {
  id: number;
  title: string;
  orientation: 'h' | 'v';
  src: string;
  video: string;
  year: string;
}

export function getVideos(id?: number): Video[] {
  let counter = 0;
  const videos: Video[] = [
    {
      id: counter++,
      title: "Ear playing at Woodbridge Library",
      orientation: 'h',
      src: '/img/ear.png',
      video: '/vid/ear.mov',
      year: '2017',
    },
    {
      id: counter++,
      title: "Rosendo trying not to laugh as Rob records Ear vocals at Jason Barnett's house",
      orientation: 'v',
      src: '/img/rob.png',
      video: '/vid/rob.mov',
      year: 'May 2017',
    },
    {
      id: counter++,
      title: 'Ace of Base Karaoke (Ear tour)',
      orientation: 'v',
      src: '/img/karaoke.png',
      video: '/vid/karaoke.mov',
      year: 'Iowa City 2019',
    },
    {
      id: counter++,
      title: "Love Actually: Sticky Rice Edition",
      orientation: 'v',
      src: '/img/love-actually.png',
      video: '/vid/love-actually.mov',
      year: "Valentine's Day 2020",
    },
  ];

  return id === undefined ? videos : videos.filter(video => id === video.id);
}
